<template>
  <div style="text-align: center;position: fixed; flex-direction: column;height: 98vh; width: 100% ">
<!--    <van-pull-refresh style="height: 100%; width: 100%" v-model="refreshing" @refresh="onRefresh">-->
<!--      -->
<!--    </van-pull-refresh>-->
    <van-list
        style="height:90%;width:100%;overflow-y:auto;"
        v-model="loading"
        :finished="finished"
        @load="onLoad"
    >
      <!-- 第一种 多选 -->
<!--      <div v-for="(item, index_p) in list" :key="item.id" style="text-align: left">-->
<!--        <h1 style="text-align: left;size: 26px;line-height:150%; margin: 10px;"> {{ item.topic_no }}、{{ item.topic_text }}</h1>-->
<!--        <van-cell-group>-->
<!--          <van-cell-->
<!--              v-for="(option, index) in item.topic_options"-->
<!--              clickable-->
<!--              :key="option.id"-->
<!--              title-style="max-width:5rem;"-->
<!--              :title="option.options"-->
<!--              :value="option.option_text"-->
<!--              @click="toggle(index, index_p)"-->
<!--          >-->
<!--            <template #right-icon>-->
<!--              <van-checkbox v-model="result[index_p][index]" style="padding-left: 10px;" :name="option.id + '-' + item.id" ref="checkboxes" />-->
<!--            </template>-->
<!--          </van-cell>-->
<!--        </van-cell-group>-->
<!--      </div>-->
<!-- 第二种 单选 -->
        <div v-for="(item, index_p) in list" :key="item.id" style="text-align: left; padding: 0 5px;">
          <h1 style="text-align: left;size: 26px;line-height:150%; margin: 10px;"> {{ item.topic_no }}、{{ item.topic_text }}</h1>
          <van-radio-group v-model="result[index_p]" @change="groupChange(index_p,result[index_p])">
            <van-cell-group v-for="(option, index) in item.topic_options">
              <van-cell clickable @click="singleClick(option)">
<!--                <template #right-icon>-->
                <van-radio :name="option.id" checked-color="#EE0A24" style="text-align: left" >{{ option.options +'.'+option.option_text }}</van-radio>
<!--                </template>-->
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
    </van-list>
    <div v-if="this.list.length>0" style="text-align: center;background-color: transparent;margin-top: auto;padding: 0px;height: 50px;line-height: 50px;">
      <van-button type="danger" style="width: 60%;margin: 0;" @click="commitAnswers" :loading="submitting" loading-text="提交中...">提交</van-button>
    </div>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant';
import store from "@/store";
import router from "@/router";

export default {
  name: "index",
  data() {
    return {
      list: [],
      result: [3,],
      choicedResult:[],
      submitting: false,
      loading: false,
      finished: false,
      refreshing: false,
      activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png'
    };
  },
  mounted() {
    store.commit("toggleTabbarShow", false)
  },
  methods: {
    onLoad() {
      this.$http.get("/topic/")
          .then(res => {
            if (this.refreshing) {
              this.list = [];
              this.refreshing = false;
            }
            console.log(res);
            if (res.code === 1000) {
              this.list = res.data;
              this.result = this.list.map((item,index) => {
                return []
              })
            }
          }).finally(()=> {
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        this.finished = true;
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    toggle(index, index_p,checked_id) {
      // console.log(index, index_p);
      // this.result[index_p][index] = this.result[index_p][index]? !this.result[index_p][index]:true
      // this.$refs.checkboxes[index].toggle({
      //   checked: this.result[index_p][index]
      // });
      // this.result[index_p][index] = ischecked
      // this.$refs.radioGroup[index_p].value = true
      this.result[index_p] = checked_id
      console.log(this.result)
    },
    groupChange (event,index) {
      console.log(this.result)
      console.log('groupChange', event,index);
      // event.map(e => {
      //   const indexs = e.split('-')
      //   const p_index = indexs[1]
      //   const c_index = indexs[0]
      //   console.log(p_index,c_index);
      // })
    },
    singleClick (data) {
      // this.result[p_index] = data + ''
      console.log(data)
      // console.log(data,p_index);
      // this.groupChange(p_index,data)
    },
    commitAnswers () {

      const answers = {
        topic_options: ''
      }

      answers.topic_options = this.result.filter((item,index) => {
        if ('number' === typeof item){
          return item
        }
        // console.log('['+index+']========',typeof item);
        // const data = item.map((item_c,index_c)=>{
        //
        //   if (item_c) {
        //     mychoice[index_c]
        //     answers.topic_options.push(this.list[index].topic_options[index_c].id)
        //   }
        // }).filter(item => {
        //   if (item) {
        //     return item
        //   }
        // })
      }).toString()

      if (answers.topic_options.length === 0) {
        Toast('请选择')
        return
      } else if (answers.topic_options.split(',').length !== this.list.length) {
        Toast('题目全部作答后再提交')
        return
      }
      console.log('答案---》》》',this.result);
      // if (this.result){return}
      this.submitting = true
      // {topic_options: answers.topic_options.toString()}
      this.$http.get("/topic/score/", answers)
          .then(res => {
            console.log(res);
            if (res && res.code === 1000) {
              this.result = [];
              this.choicedResult = []
              if (res.level<2) {
                Dialog.confirm({
                  title: '结果',
                  message: '本次测评等级: R'+res.level + '。测评等级过低，不符合购买条件！！！',
                  confirmButtonText: '重新考试',
                  cancelButtonText: '退出'
                })
                    .then(() => {
                      // 继续考试
                    })
                    .catch(() => {
                      // 退出页面，回到登录页面
                      // 删除本地token
                      store.commit("deleteToken")
                      // 不让底部导航栏展示
                      store.commit("toggleTabbarShow", false)
                      router.replace("/login")
                    });
              }else {
                // Toast("本次考试得分："+res.score);
                Dialog.confirm({
                  title: '结果',
                  message: '本次测评等级: R'+res.level + '。恭喜你通过测评！！！',
                  confirmButtonText: '进入',
                  cancelButtonText: '退出'
                })
                    .then(() => {
                      // 进入系统
                      // 切换tabbar为显示状态
                      store.commit('toggleTabbarShow', true);
                      router.replace("/")
                    })
                    .catch(() => {
                      // 退出页面，回到登录页面
                      // 删除本地token
                      store.commit("deleteToken")
                      // 不让底部导航栏展示
                      store.commit("toggleTabbarShow", false)
                      router.replace("/login")
                    });
              }
            }
          }).finally(()=> {
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        this.finished = true;
        this.submitting = false
      })
    }
  }
}
</script>

<style scoped>
.van-cell__title{
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.van-cell__value {
  -webkit-box-flex: 4;
  -webkit-flex: 4;
  flex: 4;
  text-align: right;
}
</style>
