<template>
<!--  <van-tabs-->
<!--      v-model="active"-->
<!--      animated-->
<!--      color="#1296db"-->
<!--      swipeable-->
<!--  >-->
<!--    <van-tab title="题目">-->
<!--      <do-exam></do-exam>-->
<!--    </van-tab>-->
<!--    <van-tab title="答题记录">-->
<!--      <exam-record></exam-record>-->
<!--    </van-tab>-->
<!--  </van-tabs>-->

  <div style="padding: 0px;">
    <van-nav-bar title="风险等级测评" :border="false" :fixed="true" @click-left="goback" left-arrow/>
    <van-divider></van-divider>
    <do-exam></do-exam>
  </div>
</template>

<script>
import DoExam from "@/views/exam/DoExam";
import ExamRecord from "@/views/exam/ExamRecord";

export default {
  name: "index",
  data() {
    return {
      active: 0
    };
  },
  components: {
    DoExam,
    ExamRecord
  },
  methods: {
    goback () {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>
