<template>
  <div style="padding: 20px; text-align: center">
    答题记录
  </div>
</template>

<script>
export default {
  name: "ExamRecord"
}
</script>

<style scoped>

</style>
